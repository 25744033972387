<!--  -->
<template>
  <van-popup v-model="showLogin" :close-on-click-overlay="false" round>
    <div class="pop-content">
      <p class="lk-font-18 lk-text-center lk-p-t-10 lk-p-b-10 font-1">
        登录绑定
      </p>
      <van-field
        class="login-field"
        v-model="login.phone"
        type="digit"
        label="手机号"
        maxlength="11"
        @blur="blur"
      />
      <van-field
        class="login-field"
        v-model="login.phoneCode"
        type="digit"
        label="验证码"
        maxlength="6"
        @blur="blur"
      >
        <template #button>
          <MsgCode
            :phone="login.phone"
            type="ACTIVITY_SMS_SEND"
            template-code="ACTIVITY_SMS_SEND"
            @success="onSuccess"
          />
        </template>
      </van-field>
      <van-button
        class="pop-login"
        :loading="loading"
        round
        @click.native.stop="handleConfirmBind"
      >
        登录
      </van-button>
    </div>
  </van-popup>
</template>

<script>
import { Toast } from 'vant';
import { decode } from 'js-base64';
import MsgCode from '@/components/Msgcode.vue';
import {
  getWeixinOpenIdApi,
  getBindByOpenidApi,
  bindMemberApi,
} from '@/api/entery';
import { getQueryStringByUrl } from '@/utils/getQueryByUrl';
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  name: 'Redirect',
  components: {
    MsgCode,
  },
  data() {
    return {
      toast: null,
      showLogin: false,
      loading: false,
      openId: '',
      code: '',
      cardInfo: {
        cardNo: '', // 扫码进入时传参
        serialNo: '', // 扫码进入时传参
        cardId: '', // 公众号菜单进入时传参
      },
      login: {
        phone: '',
        phoneCode: '',
      },
      subCardType: null,
    };
  },
  // 公众号菜单进入时传入cardId，扫码进入时传入cardNo
  // 1、先判断是否有openid，
  //    没有就去获取openid
  //    有，就通过openid查看用户绑定信息
  // 2、没有绑定，就先登录绑定（也就是说，登录这一操作，是为了将卡和用户手机号绑定起来）
  //    绑定了，就直接进入当前卡的首页

  //  'b25QTHV2bFFIT0E2Y19tWThidG9ycEtXdkVTcw=='
  beforeRouteEnter(to, from, next) {
    const OPEN_ID = localStorage.getItem('OPEN_ID');
    // const OPEN_ID = 'onPLuvlQHOA6c_mY8btorpKWvESs';
    // const OPEN_ID = 'onPLuvmh27oEqlwX8wJA-JDr1ghg';
    // ...扫码或者公众号菜单进入
    next(async (vm) => {
      vm.showLogin = false;
      vm.toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      vm.cardInfo = {};
      // 处理参数
      vm.cardInfo = await getQueryStringByUrl();
      console.log('...扫码或者公众号菜单进入', vm.cardInfo);
      vm.code = vm.cardInfo.code;
      vm.subCardType = vm.cardInfo.subCardType;
      await vm.SET_ORG_ID(vm.cardInfo.orgId || '100000821');
      await vm.SET_CARD_ID(vm.cardInfo.cardId || '');
      await vm.SET_WX_APP_ID(vm.cardInfo.appId || '');
      vm.cardInfo.code && delete vm.cardInfo.code;
      vm.cardInfo.state && delete vm.cardInfo.state;
      vm.cardInfo.appId && delete vm.cardInfo.appId;
      vm.setCardInfo(vm.cardInfo);
      if (OPEN_ID) {
        vm.openId = OPEN_ID;
      } else {
        await vm.getWxOpenIdAction();
      }
      await vm.getBindInfoAction();
    });
  },
  computed: {
    ...mapState('app', ['findFlag', 'orgId']),
  },
  methods: {
    ...mapMutations('app', ['SET_ORG_ID', 'SET_WX_APP_ID']),
    ...mapMutations('card', ['SET_CARD_ID']),
    ...mapActions('entery', ['setMemberInfo']),
    ...mapActions('card', [
      'setCardInfo',
      'getCardInfoAction',
      'getCardListAction',
    ]),

    // 获取openId
    async getWxOpenIdAction() {
      const { code, content } = await getWeixinOpenIdApi({
        code: this.code,
        appId: window.$appId,
        orgId: this.orgId || localStorage.getItem('ORG_ID'),
      });
      console.log(code, content);
      if (code === 200) {
        this.openId = decode(content.thirdUserId);
        localStorage.setItem('OPEN_ID', this.openId);
      }
    },
    // 通过openid获取用户绑定信息
    async getBindInfoAction() {
      console.log(this.findFlag);
      const { code, content } = await getBindByOpenidApi({
        openId: this.openId,
        findFlag: this.findFlag, // 是否需要查询当前手机号绑定情况
      });
      if (code === 200) {
        content && (await this.setMemberInfo(content));
        console.log('content', content);
        if (!content.memberNo || !content.phone) {
          // console.log('没有绑定，先登录');
          this.loading = false;
          this.toast.clear();
          this.showLogin = true;
          return false;
        }
        // console.log('已绑卡，查询卡详情，再决定跳转到哪个页面');
        // 只有归属于当前用户才会跳转到卡详情；否则进入cardList
        //subCardType 1虚拟卡 其他实体卡
        if (this.subCardType == 1) {
          this.$router.push({
            name: 'ReceiveCard', // 虚拟卡领卡链接直接跳到领卡页
          });
          this.toast.clear();
        } else {
          this.handlePageToDetail(true);
        }
      }
    },

    async handleConfirmBind() {
      this.loading = true;
      if (!this.login.phone || !this.login.phoneCode) {
        this.$toast('请正确填写');
        this.loading = false;
        return false;
      }
      try {
        const { code, content } = await bindMemberApi({
          ...this.login,
          openId: this.openId,
          scanAppName: 'weixin',
          appId: localStorage.getItem('WX_APP_ID') || '', // 微信公众号appId
          cardType: 3,
        });
        if (code === 200) {
          console.log(content);
          content && (await this.setMemberInfo(content));
          this.$toast('登录成功');
          // / 如果要登录，登录成功，跳转列表页，在列表页判断逻辑：未领卡、已领卡、多张卡
          const data = await this.getCardListAction();
          if (data.code === 200 && data.content && data.content.length === 1) {
            this.$router.replace({
              name: 'User',
              query: {
                cardNo: data.content[0].cardNo,
                serialNo: data.content[0].serialNo,
              },
            });

            return false;
          } else {
            this.handlePageToCardList();
          }

          this.loading = false;
          this.showLogin = false;
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    // 跳转详情
    async handlePageToDetail(loading) {
      console.log(loading, this.getCardInfoAction);
      try {
        await this.getCardInfoAction({ check: true });
        if (loading) {
          this.toast.clear();
        }
      } catch (error) {
        console.log(error);
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0,
        });
      }
    },

    async handlePageToCardList() {
      console.log('跳转列表redirect');

      // 列表展示
      localStorage.removeItem('routeFlag');
      this.$router.replace({
        name: 'CardList',
        query: {
          status: 1,
        },
      });
    },
    onSuccess() {
      console.log('发送成功');
    },
    blur() {
      setTimeout(function () {
        var scrollHeight =
          document.documentElement.scrollTop || document.body.scrollTop || 0;
        console.log('blur', scrollHeight);
        window.scrollTo(0, Math.max(scrollHeight - 1, 0));
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.login-field ::v-deep .van-field__label {
  width: 1.6rem;
}
.pop-content {
  width: 70vw;
  padding: 12px;
  .pop-login {
    background-color: #f8b500;
    color: #333333;
    font-size: 16px;
    margin: 12px 0 10px 0;
  }
}
</style>
